import { useStoreState } from "pullstate";
import { createContext, useEffect, useState } from "react";
import Store from "../../utils/Store";
import { clone, get, isEmpty, set } from "lodash";
import { Patient } from "../../types/api/Patient";
import { PatientsResponse } from "../../types/api/Patients";
import useWebsocket from "../../hooks/useWebsocket";
import constants from "../../utils/constants";
import getPatients from "./services/getPatients";
import getCounts from "./services/getCounts";
import { getEmergencyPatients } from "../Settings/utils";
import { add, format } from "date-fns";
import getMatchingPatient from "../MatchingPatient/services/getMatchingPatient";
import { downloadFile } from '../ExportMessage/services/downloadService'


export const PatientsContext = createContext({});

export const PatientsContextProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { children } = props;
  const pageSize = constants.PAGE_SIZE;
  const { user } = useStoreState(Store, (s) => ({
    user: s.user,
  }));
  const userId = get(user, ["sub"], "");
  const isLoggedin = !isEmpty(user);
  const [lastId, setLastId] = useState("");
  const [patientsData, setPatientsData] = useState<PatientsResponse | null>(null);
  const [updatedMessage, setUpdatedMessage] = useState({});
  const [unreadMessages, setUnreadMessages] = useState({});
  const [currentPatient, setCurrentPatient] = useState<Partial<Patient>>({});
  const [isPatientsLoading, setPatientsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const _persistedFilters = localStorage?.getItem(constants.PERSISTED.filterObj)
  const [filters, setFilters] = useState(_persistedFilters ? JSON.parse(_persistedFilters) : constants.DEEFAULT_FILTER);
  const _persistedSavedFilters = localStorage?.getItem(constants.PERSISTED.savedFilterData)
  const [savedFilterData, setSavedFilterData] = useState(_persistedSavedFilters ? JSON.parse(_persistedSavedFilters) : null);
  const _persistedFullWidthWorklist = localStorage?.getItem(constants.PERSISTED.isFullWidthWorklist)
  const [isFullWidthWorklist, setIsFullWidthWorklist] = useState(!!_persistedFullWidthWorklist);
  const [matchingPatients, setMatchingPatients] = useState([]);

  const { socket, wsData } = useWebsocket(`/worklist/${userId}/`, !isEmpty(user));

  useEffect(() => {
    const filterParams = getFilterParams(filters);
    const params: any = { page_size: pageSize, ...filterParams };
    if (lastId) {
      params.pagination_id = lastId;
    }
    setPatientsLoading(true);
    getPatients(params, patientsData)
      .then(async (_patientsData) => {
        let _emergencyPatients = [];
        if (!lastId) {
          const _emergencyPatientsData = await getEmergencyPatients();
          _emergencyPatients = get(_emergencyPatientsData, ["data", "content"], []);
        }

        const _updatedPatients = updatePatientList(
          _patientsData || patientsData,
          _emergencyPatients
        );
        setPatientsData(_updatedPatients);
        setPatientsLoading(false);
      })
      .catch((e) => {
        setPatientsLoading(false);
      });
  }, [lastId, pageSize, filters]);

  useEffect(() => {
    /* get total patient count just for display on top on worklist */
    const params: any = getFilterParams(filters);
    setIsCountLoading(true);
    getCounts(params)
      .then((_count) => {
        setCount(_count);
        setIsCountLoading(false);
      })
      .catch(() => {
        setCount(0);
        setIsCountLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    if (!isEmpty(currentPatient)) {
      const currentPatientUid = get(currentPatient, ["patient_uid"], 0);
      findMatchingPatient(currentPatientUid);
    }
  }, [currentPatient]);

  const getFilterParams = (_filters: typeof filters) => {
    const filterParams = {
      ...(_filters?.data ?? {}),
    };
    let startDate = get(filterParams, ["start_date"], "");
    let endDate = get(filterParams, ["end_date"], "");

    if (startDate) {
      endDate = format(add(new Date(endDate), { days: 1 }), "yyyy-MM-dd");
      startDate = format(new Date(startDate), "yyyy-MM-dd");
      set(filterParams, ["start_date"], startDate);
      set(filterParams, ["end_date"], endDate);
    }

    return filterParams;
  };

  const updatePatientUnreadCount = (
    currentPatientUid: number,
    hasNewMessage?: boolean
  ) => {
    const patients = get(patientsData, ["data", "content"], []);
    const currentPatient =
      patients && patients.find((p) => get(p, ["patient_uid"], 0) == currentPatientUid);
    let count = 0;
    if (hasNewMessage) {
      count = get(currentPatient, ["unread_message_count"], 0) + 1;
    }
    if (currentPatient) {
      set(currentPatient, ["unread_message_count"], count);
    }
  };

  const getPatientsByEmergency = (_patients: Partial<Patient>[]) => {
    const _emergencyPatients = _patients.filter(
      (_pat) => get(_pat, ["emergency", "stale"], null) === false
    );
    const _nonEmergencyPatients = _patients.filter((_pat) => {
      const stale = get(_pat, ["emergency", "stale"], null);
      return stale === true || stale === null;
    });
    return {
      _emergencyPatients,
      _nonEmergencyPatients,
    };
  };

  const formPatientStateObject = (
    _patients: Partial<Patient>[],
    _patientsData: PatientsResponse | null
  ) => {
    if (_patientsData) {
      const content = Object.assign(_patientsData, {
        data: { ..._patientsData.data, content: _patients },
      });
      return content;
    }
    return _patientsData;
  };

  const updatePatientList = (
    patientsData: PatientsResponse | null,
    _emergencyPatients: Partial<Patient>[]
  ) => {
    const patientIds = _emergencyPatients.map((_pat) => _pat.patient_uid);
    const _patientsData = clone(patientsData);
    let _patients = get(_patientsData, ["data", "content"], []);
    _patients = _patients.filter((_pat) => !patientIds.includes(_pat.patient_uid));
    _patients = [..._emergencyPatients, ..._patients];
    return formPatientStateObject(_patients, _patientsData);
  };

  const findMatchingPatient = async (_patientUid: number) => {
    if (_patientUid) {
      const matches: any = await getMatchingPatient(_patientUid);
      setMatchingPatients(matches);
    }
  };

  useEffect(() => {
    if (wsData) {
      if (
        wsData.action == "new_message" ||
        wsData.action == "update_message" ||
        wsData.action == "unsubscribe" ||
        wsData.action == "subscribe"
      ) {
        const _unreadMessages = clone(unreadMessages);
        if (wsData.action == "new_message") {
          const msgContent = get(wsData, ["content"], {});
          const msgPatientUid = get(msgContent, ["patient_uid"], 0);
          const currentPatientUid = get(currentPatient, ["patient_uid"], 0);
          // --- Kept this code for future use ---
          // const msgsList = _unreadMessages[msgPatientUid] || []
          // msgsList.unshift(msgContent)
          set(_unreadMessages, [msgPatientUid], [msgContent]); // [...msgsList]
          if (
            get(msgContent, ["user"], "") !== userId &&
            msgPatientUid != currentPatientUid
          ) {
            updatePatientUnreadCount(msgPatientUid, true);
          }
          if (
            (get(msgContent, ["image_report_uid"], 0) > 0 ||
              get(msgContent, ["image_study_uid"], 0) > 0) &&
            msgPatientUid === currentPatientUid
          ) {
            Store.update((s) => {
              s.enableViewerAPICall = false;
            });
            setTimeout(() => {
              Store.update((s) => {
                s.enableViewerAPICall = true;
              });
            }, 500);
          }
        }
        if (wsData.action == "update_message") {
          const msgContent = get(wsData, ["content"], {});
          setUpdatedMessage(msgContent);
        }
        if (wsData.action == "unsubscribe") {
          const msgPatientUid = get(wsData, ["patient_uid"], 0);
          set(_unreadMessages, [msgPatientUid], []);
        }
        if (wsData.action == "subscribe") {
          const msgPatientUid = get(wsData, ["patient_uid"], 0);

          updatePatientUnreadCount(msgPatientUid);
        }
        setUnreadMessages(_unreadMessages);
      }

      if (wsData.action == "new_patient") {
        const newPatient = get(wsData, ["content"], {});
        const _patientsData: PatientsResponse | null = clone(patientsData);
        const _patients = get(patientsData, ["data", "content"], []);
        const { _emergencyPatients, _nonEmergencyPatients } =
          getPatientsByEmergency(_patients);

        _nonEmergencyPatients.unshift(newPatient);
        const updatedPatientData = formPatientStateObject(
          [..._emergencyPatients, ..._nonEmergencyPatients],
          _patientsData
        );
        setPatientsData(updatedPatientData);
      }

      if (wsData.action === "new_emergency" || wsData.action === "update_emergency") {
        const newPatientEmergencyContent = get(wsData, ["content"], {});
        const _patientsData = clone(patientsData);
        let _patients = get(_patientsData, ["data", "content"], []);
        const patientId = get(newPatientEmergencyContent, ["patient_id"]);
        const stale = get(newPatientEmergencyContent, ["stale"], false);
        if (wsData.action === "update_emergency") {
          let _selectedEmergencyPatient = _patients.find(
            (_pat) => _pat.patient_uid === patientId
          );
          // _selectedEmergencyPatient =
          //   _selectedEmergencyPatient && _selectedEmergencyPatient[0];
          if (_selectedEmergencyPatient) {
            _selectedEmergencyPatient.emergency = stale
              ? null
              : newPatientEmergencyContent;
          }

          _patients = _patients.filter((_pat) => _pat.patient_uid !== patientId);

          const { _emergencyPatients, _nonEmergencyPatients } =
            getPatientsByEmergency(_patients);

          _patients = [
            ..._emergencyPatients,
            ...(_selectedEmergencyPatient ? [_selectedEmergencyPatient] : []),
            ..._nonEmergencyPatients,
          ];
          if (
            currentPatient &&
            _selectedEmergencyPatient &&
            currentPatient?.patient_uid === _selectedEmergencyPatient.patient_uid
          ) {
            setCurrentPatient({ ..._selectedEmergencyPatient });
          }
        } else if (wsData.action === "new_emergency") {
          const updatedPatient = _patients
            .filter((_pat) => _pat.patient_uid === patientId)
            .map((_pat) => {
              return Object.assign(_pat, {
                emergency: newPatientEmergencyContent,
              });
            });
          _patients = _patients.filter((_pat) => _pat.patient_uid !== patientId);
          _patients.unshift(get(updatedPatient, [0], {}));
        }

        const updatedPatientData = formPatientStateObject(_patients, _patientsData);
        setPatientsData(updatedPatientData);
      }

      if (wsData.action == "update_patient") {
        const _patientsData = clone(patientsData);
        const newPatient = get(wsData, ["content"], {});
        const _patients = get(_patientsData, ["data", "content"], []);
        let patientToUpdateIdx = _patients.findIndex(
          (p) => p.patient_uid === newPatient.patient_uid
        );
        if (patientToUpdateIdx >= 0) {
          _patients[patientToUpdateIdx] = newPatient;
          setPatientsData(_patientsData);
          if (currentPatient?.patient_uid === newPatient?.patient_uid) {
            setCurrentPatient(newPatient);
          }
        }
      }
      if (wsData.action === "export_download_started") {
        Store.update((s) => {
          s.showExportUpdate = true;
          s.downloadProgress = wsData.progress;
          s.exportGenFailed = false;
        });
      }
      if (wsData.action === "export_download_progress") {
        Store.update((s) => {
          s.isExportReady = false;
          s.exportGenFailed = false;
          s.downloadProgress = wsData.progress;
        });
      }
      if (wsData.action === "export_generation_complete") {
        Store.update((s) => {
          s.isExportReady = true;
          s.exportGenFailed = false;
          s.exportTimestamp = wsData.timestamp;
          s.downloadProgress = wsData.progress;
        });
        // Api call to download the Export file
        downloadFile()
      }
      if (wsData.action === "export_download_failed") {
        Store.update((s) => {
          s.showExportUpdate = true;
          s.isExportReady = false;
          s.exportGenFailed = true;
          s.exportTimestamp = wsData.timestamp;
          s.downloadProgress = wsData.progress;
        });
      }
    }
  }, [wsData]);

  return (
    <PatientsContext.Provider
      value={{
        patientsData,
        setPatientsData,
        updatePatientUnreadCount,
        updatedMessage,
        setUpdatedMessage,
        unreadMessages,
        setUnreadMessages,
        currentPatient,
        setCurrentPatient,
        getFilterParams,
        lastId,
        setLastId,
        isPatientsLoading,
        setPatientsLoading,
        filters,
        setFilters,
        count,
        isCountLoading,
        setIsFullWidthWorklist,
        isFullWidthWorklist,
        matchingPatients,
        setMatchingPatients,
        savedFilterData,
        setSavedFilterData,
        socket,
        wsData,
      }}
    >
      {children}
    </PatientsContext.Provider>
  );
};
